import close from '@a/gradient-close.svg';
import open from '@a/gradient-open.svg';
import styled from '@emotion/styled';
import { AccordionButton, AccordionItem as Item, useAccordionItemContext } from '@reach/accordion';
import '@reach/accordion/styles.css';
import AnimateHeight from 'react-animate-height';

const AccordionItem = styled(Item)`
    margin: 16px auto;
    border-radius: 8px;
    box-shadow: none;
    transition: box-shadow 0.3s ease-in-out;
    max-width: 700px;
    background-color: ${({ theme }) => theme.white};

    > h6 {
        margin: 0;
    }

    p {
        padding: 8px 16px 24px;
        margin: 0;
        text-align: left;
        transform: translateY(-16px);
        opacity: 0;
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
        color: #1d1d1dcc;
    }

    &[data-state='open'] {
        box-shadow: 0px 25px 55px rgba(126, 126, 126, 0.15);

        p {
            transform: translateY(0);
            opacity: 1;
        }
    }

    @media (min-width: 1280px) {
        margin: 0;

        p {
            padding: 16px 16px 32px;
        }
    }
`;

const Question = styled(AccordionButton)`
    text-align: left;
    position: relative;
    padding: 16px;
    color: ${({ theme }) => theme.black};
    font-weight: 500;
    font-size: 1rem;
    width: 100%;
    background-color: ${({ theme }) => theme.white};
    border-radius: 8px;
    border: 1px solid transparent;
    outline: none;
    transition: border 0.3s ease-in-out, border-radius 0.3s ease-in-out;

    ::before,
    ::after {
        position: absolute;
        right: 16px;
        top: 16px;
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    ::before {
        content: url(${open});
        opacity: 1;
        transform: translateY(0);
    }

    ::after {
        content: url(${close});
        opacity: 0;
        transform: translateY(5px);
    }

    :hover,
    :focus {
        cursor: pointer;
        border-color: ${({ theme }) => theme.green};
    }

    &[data-state='open'] {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid transparent;

        ::before {
            opacity: 0;
            transform: translateY(-5px);
        }

        ::after {
            opacity: 1;
            transform: translateY(0);
        }
    }
`;

type AccordionItemProps = {
    children: React.ReactNode;
    question: string;
};

const AnimatedPanel = ({ children }: { children: React.ReactNode }) => {
    const { isExpanded } = useAccordionItemContext();

    return (
        <AnimateHeight duration={300} easing="ease-in-out" height={isExpanded ? 'auto' : 0}>
            {children}
        </AnimateHeight>
    );
};

export const SingleQuestion = ({ question, children }: AccordionItemProps) => {
    return (
        <AccordionItem>
            <h6>
                <Question>{question}</Question>
            </h6>

            <AnimatedPanel>{children}</AnimatedPanel>
        </AccordionItem>
    );
};
