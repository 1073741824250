import styled from '@emotion/styled';
import { Accordion } from '@reach/accordion';
import Masonry from 'react-masonry-css';
import { h1, px } from './layout/GlobalStyles';

const FaqSection = styled.section<{ homepage?: boolean }>`
    ${px}
    margin: 64px 0;
    text-align: center;

    > h5 {
        ${h1};
        color: ${({ theme }) => theme.black};
        background-image: ${({ theme, homepage }) => (homepage ? theme.gradient : 'none')};
        background-clip: ${({ homepage }) => (homepage ? 'text' : 'unset')};
        text-fill-color: ${({ homepage }) => (homepage ? 'transparent' : 'unset')};
        margin: 0 0 64px;
    }
`;

const StyledAccordion = styled(Accordion)`
    @media (min-width: 1280px) {
        width: 71vw;
        margin: 0 auto;
        max-width: 1200px;

        .faq-masonry-grid {
            display: flex;
            margin-left: -16px; /* gutter size offset */
        }
        .faq-masonry-grid_column {
            padding-left: 16px; /* gutter size */
            background-clip: padding-box;
        }

        .faq-masonry-grid_column > div {
            margin-bottom: 16px;

            :last-of-type {
                margin-bottom: 0;
            }
        }
    }
`;

type FaqProps = {
    children: React.ReactNode;
    homepage?: boolean;
};

export const Faq = ({ children, homepage }: FaqProps) => {
    return (
        <FaqSection homepage={homepage}>
            <h5>Frequently Asked Questions</h5>
            <StyledAccordion collapsible multiple>
                <Masonry
                    breakpointCols={{ default: 2, 1279: 1 }}
                    className="faq-masonry-grid"
                    columnClassName="faq-masonry-grid_column"
                >
                    {children}
                </Masonry>
            </StyledAccordion>
        </FaqSection>
    );
};
